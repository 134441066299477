import React from 'react';
import './Courses.css'; // Assuming you style it with a CSS file
import c1 from '../Images/datascience.png';
import c2 from '../Images/fullstackjava.png';
import c3 from '../Images/webdevelopment.png';
import c4 from '../Images/powerbi.png';
import c5 from '../Images/uiux.png';
import c6 from '../Images/salesforce.png';

const Courses = () => {
  const courses = [
    {
      id: 1,
      title: 'Data Science',
      description:
        'Discover the power of data with our Data Science course. Gain hands-on experience in analytics, machine learning, and data visualization, and more.',
      img: c1, // Replace with actual path
    },
    {
      id: 2,
      title: 'Full Stack Java',
      description:
        'Learn to code and develop both the frontend and backend of web applications using Java technologies, making them proficient full-stack Java developers.',
      img: c2, // Replace with actual path
    },
    {
      id: 3,
      title: 'Web Development',
      description:
        'Learn basic to advanced programming languages, such as HTML, CSS, Java, Angular, Node.Js, React, SQL, and more. And apply your learnings on a live project.',
      img:c3 , // Replace with actual path
    },
    {
      id: 4,
      title: 'Power BI',
      description:
        'Discover the power of data with our Data Science course. Gain hands-on experience in analytics, machine learning, and data visualization, and more.',
      img:c4 , // Replace with actual path
    },
    {
      id: 5,
      title: 'UI/UX Design',
      description:
        'Learn to code and develop both the frontend and backend of web applications using Java technologies, making them proficient full-stack Java developers.',
      img:c5 , // Replace with actual path
    },
    {
      id: 6,
      title: 'Salesforce',
      description:
        'Learn basic to advanced programming languages, such as HTML, CSS, Java, Angular, Node.Js, React, SQL, and more. And apply your learnings on a live project.',
      img: c6, // Replace with actual path
    },
  ];

  return (
    <div className="courses-container">
      <h2>Job Oriented Courses</h2>
      <div className="courses">
        {courses.map(course => (
          <div key={course.id} className="course-card">
            <img src={course.img} alt={course.title} />
            <h3>{course.title}</h3>
            <p>{course.description}</p>
            <button className="details-btn">View Details</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Courses;
