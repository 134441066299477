import React from 'react';
import './NContact.css'; // Create the necessary CSS file for styling

// Import the images from src/Images
import FacebookIcon from '../Images/Facebook.png';
import WhatsAppIcon from '../Images/Whatsapp.png';
import LinkedInIcon from '../Images/Linkedin.png';
import InstagramIcon from '../Images/Instagram.png';
import YouTubeIcon from '../Images/YouTubeIcon.png';

const NContact = () => {
  return (
    <div className="contact-container1">
      <div className="contact-info1">
        <span>📞 +91-866 916 8920</span>
        <span>📧 info@internhubb.in</span>
     
      </div>
      <div className="social-icons">
        <a href="https://www.facebook.com/profile.php?id=61564161129537" target="_blank" rel="noreferrer">
          <img src={FacebookIcon} alt="Facebook" />
        </a>
        
        <a href="https://www.linkedin.com/company/internhubb/?viewAsMember=true" target="_blank" rel="noreferrer">
          <img src={LinkedInIcon} alt="LinkedIn" />
        </a>
        <a href="https://www.instagram.com/internhubb_it_solutions/" target="_blank" rel="noreferrer">
          <img src={InstagramIcon} alt="Instagram" />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noreferrer">
          <img src={YouTubeIcon} alt="YouTube" />
        </a>
      </div>
    </div>
  );
};

export default NContact;
