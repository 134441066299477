import React from 'react';
import './Partners.css';
import partner1 from '../Images/partner1.png';
import partner2 from '../Images/partner2.png';
import partner3 from '../Images/partner3.png';

const Partners = () => {
  return (
    <section className="partners-section">
      <div className="partners-header">
        <h2 style={{color:'white'}}>Our Partners</h2>
      </div>
      <div className="partners-marquee">
        <div className="partners-logos">
          <img src={partner1} alt="Partner 1" />
          <img src={partner2} alt="Partner 2" />
          <img src={partner3} alt="Partner 3" />
        </div>
      </div>
    </section>
  );
};

export default Partners;
