// DigitalMarketingCoursePage.js
import React from 'react';
import './DigitalMarketingCoursePage.css';
import trainingIcon from '../Images/training.gif';
import placementIcon from '../Images/placement.png';
import internshipIcon from '../Images/internship.png';
import courseImage from '../Images/digital.jpg';
import { Link } from 'react-router-dom';


const DigitalMarketingCoursePage = () => {
  return (
    <div className="course-container">
     <header className="course-header">
        <img src={courseImage} alt="Full Stack Course" className="course-image" />
        <div className="header-text">
          <h1>Digital Marketing</h1>
          <p>Jumpstart your career with comprehensive training, internships, and placement support at InternHubb.</p>
          <Link to="/enroll" className="enroll-button">Enroll Now</Link>
        </div>
      </header>

      <section className="course-overview">
        <h2>Course Overview</h2>
        <p>Learn SEO, social media, content marketing, PPC, and analytics in our comprehensive course.</p>
      </section>

    

      <section className="course-modules">
        <h2>What You'll Learn</h2>
        <div className="modules-grid">
          <div className="module-item">SEO Fundamentals</div>
          <div className="module-item">Social Media Marketing</div>
          <div className="module-item">Content Strategy</div>
          <div className="module-item">Analytics and Data</div>
          {/* {<div className="module-item">Industry Project Work</div> */} 
          {/* <div className="module-item">Git and Version Control</div> */}
        </div>
      </section>


      <section className="course-benefits">
        <h2>Why InternHubb?</h2>
        <div className="benefits-grid">
          <div className="benefit">
            <img src={trainingIcon} alt="Training Icon" />
            <h3>Industry-Driven Training</h3>
            <p>Our courses are designed with expert input to ensure job readiness.</p>
          </div>
          <div className="benefit">
            <img src={internshipIcon} alt="Internship Icon" />
            <h3>Hands-On Internships</h3>
            <p>Apply your skills in real projects with our partner companies.</p>
          </div>
          <div className="benefit">
            <img src={placementIcon} alt="Placement Icon" />
            <h3>Guaranteed Placement Assistance</h3>
            <p>We connect you to industry roles that align with your skills.</p>
          </div>
        </div>
        </section>
    </div>
    
  );
};

export default DigitalMarketingCoursePage;
