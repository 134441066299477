// SalesforceCoursePage.js
import React from 'react';
import './SalesforceCoursePage.css';
import trainingIcon from '../Images/training.gif';
import placementIcon from '../Images/placement.png';
import internshipIcon from '../Images/internship.png';
import courseImage from '../Images/salesforce.avif';
import { Link } from 'react-router-dom';

const SalesforceCoursePage = () => {
  return (
    <div className="course-container">
      <header className="course-header">
        <img src={courseImage} alt="Full Stack Course" className="course-image" />
        <div className="header-text">
          <h1>Salesforce</h1>
          <p>Jumpstart your career with comprehensive training, internships, and placement support at InternHubb.</p>
          <Link to="/enroll" className="enroll-button">Enroll Now</Link>
        </div>
      </header>

    

      <section className="course-overview">
        <h2>Course Overview</h2>
        <p>Learn to leverage Salesforce for managing customer relationships and driving sales.</p>
      </section>

    

      <section className="course-modules">
        <h2>What You'll Learn</h2>
        <div className="modules-grid">
          <div className="module-item">Salesforce Basics</div>
          <div className="module-item">Wireframing and Prototyping</div>
          <div className="module-item">Customization and Automation</div>
          <div className="module-item">Salesforce Best Practices</div>
          {/* {<div className="module-item">Industry Project Work</div> */} 
          {/* <div className="module-item">Git and Version Control</div> */}
        </div>
      </section>


      <section className="course-benefits">
        <h2>Why InternHubb?</h2>
        <div className="benefits-grid">
          <div className="benefit">
            <img src={trainingIcon} alt="Training Icon" />
            <h3>Industry-Driven Training</h3>
            <p>Our courses are designed with expert input to ensure job readiness.</p>
          </div>
          <div className="benefit">
            <img src={internshipIcon} alt="Internship Icon" />
            <h3>Hands-On Internships</h3>
            <p>Apply your skills in real projects with our partner companies.</p>
          </div>
          <div className="benefit">
            <img src={placementIcon} alt="Placement Icon" />
            <h3>Guaranteed Placement Assistance</h3>
            <p>We connect you to industry roles that align with your skills.</p>
          </div>
        </div>
        </section>


    </div>
  );
};

export default SalesforceCoursePage;
