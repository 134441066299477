import React from 'react';
import './FullStackCourse.css';
import courseImage from '../Images/fullstack.jpg';
import trainingIcon from '../Images/training.gif';
import placementIcon from '../Images/placement.png';
import internshipIcon from '../Images/internship.png';
import { Link } from 'react-router-dom';

const FullStackCourse = () => {
  return (
    <div className="course-container">
      {/* Course Header */}
      <header className="course-header">
        <img src={courseImage} alt="Full Stack Course" className="course-image" />
        <div className="header-text">
          <h1>Full Stack Java Development</h1>
          <p>Jumpstart your career with comprehensive training, internships, and placement support at InternHubb.</p>
          <Link to="/enroll" className="enroll-button">Enroll Now</Link>
        </div>
      </header>

      {/* About the Course Section */}
      <section className="course-introduction">
        <h2>About the Course</h2>
        <p>Learn both front-end and back-end Java development with real projects and hands-on exercises. Our Full Stack Java Development course equips you with job-ready skills to master every layer of web application development.</p>
      </section>

      {/* Learning Modules */}
      <section className="course-modules">
        <h2>What You'll Learn</h2>
        <div className="modules-grid">
          <div className="module-item">HTML, CSS, and JavaScript for front-end development</div>
          <div className="module-item">Advanced Java and Spring Boot</div>
          <div className="module-item">Database Management with SQL and NoSQL</div>
          <div className="module-item">API Development and Integration</div>
          <div className="module-item">Cloud Deployment</div>
          <div className="module-item">Git and Version Control</div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="course-benefits">
        <h2>Why InternHubb?</h2>
        <div className="benefits-grid">
          <div className="benefit">
            <img src={trainingIcon} alt="Training Icon" />
            <h3>Industry-Driven Training</h3>
            <p>Our courses are designed with expert input to ensure job readiness.</p>
          </div>
          <div className="benefit">
            <img src={internshipIcon} alt="Internship Icon" />
            <h3>Hands-On Internships</h3>
            <p>Apply your skills in real projects with our partner companies.</p>
          </div>
          <div className="benefit">
            <img src={placementIcon} alt="Placement Icon" />
            <h3>Guaranteed Placement Assistance</h3>
            <p>We connect you to industry roles that align with your skills.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FullStackCourse;
