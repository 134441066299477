import React from 'react';
import './DataScience.css';
import industryGif from '../Images/industrydriven.avif'; // Placeholder path for the GIF
import dataScienceImage from '../Images/datascience.avif'; // Placeholder path for an image
import courseImage from '../Images/datascience.avif';
import trainingIcon from '../Images/training.gif';
import placementIcon from '../Images/placement.png';
import internshipIcon from '../Images/internship.png';
import { Link } from 'react-router-dom';

const DataScience = () => {
  return (
    <div className="course-container">
      <header className="course-header">
        <img src={courseImage} alt="Full Stack Course" className="course-image" />
        <div className="header-text">
          <h1>Data Science </h1>
          <p>Jumpstart your career with comprehensive training, internships, and placement support at InternHubb.</p>
          <Link to="/enroll" className="enroll-button">Enroll Now</Link>
        </div>
      </header>
      {/* <section className="course-intro">
        <img src={dataScienceImage} alt="Data Science" className="intro-image" />
        <p className="intro-text">
          Our Data Science program is designed to empower you with the most in-demand skills in data analysis, machine learning, and more.
          Get the experience you need to excel in this high-growth field.
        </p>
      </section> */}

    

       {/* Learning Modules */}
       <section className="course-modules">
        <h2>What You'll Learn</h2>
        <div className="modules-grid">
          <div className="module-item">Introduction to Data Science</div>
          <div className="module-item">Data Analysis and Visualization Techniques</div>
          <div className="module-item">Machine Learning Basics</div>
          <div className="module-item">Deep Learning with Neural Networks</div>
          <div className="module-item">Industry Project Work</div>
          {/* <div className="module-item">Git and Version Control</div> */}
        </div>
      </section>

      {/* Benefits Section */}
      <section className="course-benefits">
        <h2>Why InternHubb?</h2>
        <div className="benefits-grid">
          <div className="benefit">
            <img src={trainingIcon} alt="Training Icon" />
            <h3>Industry-Driven Training</h3>
            <p>Our courses are designed with expert input to ensure job readiness.</p>
          </div>
          <div className="benefit">
            <img src={internshipIcon} alt="Internship Icon" />
            <h3>Hands-On Internships</h3>
            <p>Apply your skills in real projects with our partner companies.</p>
          </div>
          <div className="benefit">
            <img src={placementIcon} alt="Placement Icon" />
            <h3>Guaranteed Placement Assistance</h3>
            <p>We connect you to industry roles that align with your skills.</p>
          </div>
        </div>
        </section>
    </div>
  );
};

export default DataScience;
