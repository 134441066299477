import React, { useState } from 'react';
import './Header.css';
import Logo from '../Images/Logo.png';
import { Link } from 'react-router-dom';
import NContact from './NContact'; // Import the Contact component

const Header = () => {
  // State to manage dropdown open/close
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Toggle dropdown function
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen); // Toggle between true and false
  };

  return (
    <>
      {/* Add Contact component at the top */}
      <div className="header-top">
        <NContact />
      </div>

      <header className='header'>
        <div className="logo"> 
          <img src={Logo} alt="InternHubb" />
        </div>

        <nav className='navbar'>
          <ul className='nav-items'>
            {/* Use Link for navigation */}
            <li><Link to="/">Home</Link></li> 
            <li><Link to="/About">About</Link></li> 
            <li className="dropdown">
              {/* Courses tab with dropdown toggle */}
              <button className="dropbtn" onClick={toggleDropdown}>
                Courses
              </button>
              {/* Dropdown content with conditional class */}
              <ul className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
                <li><Link to="/FullStackCourse">Full Stack Java Development</Link></li>
                <li><Link to="/DataScience">Data Science</Link></li>
                <li><Link to="/WebDevCoursePage">Web Development</Link></li>
                <li><Link to="/DigitalMarketingCoursePage">Digital Marketing</Link></li>
                <li><Link to="/UIDesignCoursePage">UI/UX Design</Link></li>
                <li><Link to="/SalesforceCoursePage">Salesforce</Link></li>
                <li><Link to="/PowerBICoursePage">Power BI</Link></li>
              </ul>
            </li>
            <li><Link to="/InternshipCards">Internship</Link></li>
            <li><Link to="/ContactForm">Contact </Link></li>
          </ul>





        </nav>


       

        <div className="auth-buttons">
          {/* Use Link for Login and Register buttons */}
          <Link to="/Login">
            <button className="login">Login</button>
          </Link>

          <Link to="/SignIn">
            <button className="register">Register</button>
          </Link>
        </div>
      </header>
    </>
  );
};

export default Header;
