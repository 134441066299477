import React from 'react';
import Hero from '../Components/Hero';

import Services from '../Components/Services';
import Book from '../Components/Book';
import Courses from '../Components/Courses';
import Testimonials from '../Components/Testimonials';
import Partners from '../Components/Partners';


const HomePage = () => {
  return (
    <div>
      {/* Hero Section */}
      <Hero />

      

      <Services />

      <Book />
      <Courses />

      {/* Testimonials Section */}
      <Testimonials />

      {/* Partners Section */}
      <Partners />
    </div>
  );
};

export default HomePage;
