import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <h3>Quick Links</h3>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/bookmarks">My Bookmarks</a></li>
          <li><a href="/edit-resume">Edit Resume</a></li>
          <li><a href="/edit-preference">Edit Preference</a></li>
        </ul>
      </div>

      <div className="footer-section">
        <h3>Internship Location</h3>
        <ul>
          <li><i className="fa fa-map-marker"></i> Internship in Pune</li>
          <li><i className="fa fa-map-marker"></i> Internship in Mumbai</li>
          <li><i className="fa fa-map-marker"></i> Internship in Hyderabad</li>
          <li><i className="fa fa-map-marker"></i> Internship in Delhi</li>
        </ul>
      </div>

      <div className="footer-section">
        <h3>Contact Us</h3>
        <p>info@internhubb.com</p>
        <div className="social-icons">
          <a href="https://www.linkedin.com/company/internhubb/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-linkedin"></i>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61564161129537" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-facebook"></i>
          </a>
          <a href="https://www.instagram.com/internhubb_it_solutions/" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-instagram"></i>
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
