import React from 'react';
import './Hero.css';
import hero from '../Images/Hero.png';
import Logo from '../Images/Logo.png';
const Hero = () => {
  return (
    <section className="hero">
     <div className="hero-image">
        {/* <img src={hero} alt="InternHubb" /> */}
      </div>
      <div className="hero-content">
      <img src={Logo} alt="InternHubb" className='Logo' />
        <p>Internhubb is a forward-thinking 
platform thatprovides comprehensive 
IT courses with a unique Pay After 
Placement model, making high-quality 
education accessible to everyone</p>
        <button className="read-more">Read More</button>
      </div>
     
    </section>
  );
};

export default Hero;
