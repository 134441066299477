import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import HomePage from './Screens/HomePage';
import About from './Components/About';

import Testimonials from './Components/Testimonials';
import Partners from './Components/Partners';
import SignInForm from './Screens/SignInForm';
import Login from './Screens/Login';
import InternshipCards from './Screens/InternshipCards';
import ContactForm from './Screens/ContactForm';
import FullStackCourse from './Components/FullStackCourse';
import DataScience from './Components/DataScience';
import WebDevCoursePage from './Components/WebDevCoursePage';
import DigitalMarketingCoursePage from './Components/DigitalMarketingCoursePage';
import UIDesignCoursePage from './Components/UIDesignCoursePage';
import SalesforceCoursePage from './Components/SalesforceCoursePage';
import PowerBICoursePage from './Components/PowerBICoursePage';
import WhatsAppButton from './Components/WhatsAppButton';



function App() {
  return (
    <Router>
      <div className="App">
        {/* Header - Common Across All Pages */}
        <Header />

        {/* Main Content */}
        <Routes>
          {/* Home Page */}
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />}   />
         <Route path="FullStackCourse " element={<FullStackCourse />} />
          <Route path="SignIn" element={<SignInForm />} />
          <Route path="Login" element={<Login />} />

          <Route path="InternshipCards" element={<InternshipCards />} />
          
          
          
          {/* Individual Routes for Other Sections */}
         
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/ContactForm" element={<ContactForm />} />
          <Route path="WebDevCoursePage" element={<WebDevCoursePage/>}/>
          <Route path="DataScience" element={<DataScience/>}/>
          <Route path="/FullStackCourse" element={<FullStackCourse/>} />
          <Route path="/DigitalMarketingCoursePage" element={<DigitalMarketingCoursePage />} />
           <Route path="/UIDesignCoursePage" element={<UIDesignCoursePage />} />
           <Route path="/SalesforceCoursePage" element={<SalesforceCoursePage />} />
           <Route path="/PowerBICoursePage" element={<PowerBICoursePage />} />
        </Routes>

        {/* Footer - Common Across All Pages */}
        <Footer />
      </div>
      <WhatsAppButton phoneNumber="8669168920" message="Hello! Welcome to Internhubb" />
    </Router>
  );
}

export default App;
